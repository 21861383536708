<template>
  <div>
    <p>Why am I feeling called to this adventure?</p>
    <InputFormContainer
      meta-key="my-intention-why"
      @save="saveChanges"
      :height="100"
    />
    <p>Who will read this?</p>
    <InputFormContainer
      meta-key="my-intention-who"
      @save="saveChanges"
      :height="100"
    />
    <p>How do I want them to feel when they read it?</p>
    <InputFormContainer
      meta-key="my-intention-how"
      @save="saveChanges"
      :height="100"
    />
    <p>What do I want to do with it when it's completed?</p>
    <InputFormContainer
      meta-key="my-intention-what"
      @save="saveChanges"
      :height="100"
    />
  </div>
</template>

<script>
import InputFormContainer from "../InputFormContainer.vue";
export default {
  components: {
    InputFormContainer,
  },
  data() {
    return {
      content: "",
    };
  },
  methods: {
    saveChanges({ metaKey, content }) {
      switch (metaKey) {
        default:
          this.$store.dispatch("updateUserMeta", [
            metaKey,
            content,
            ({ message }) => {
              this.$toast.open({
                message,
                position: "bottom-left",
                type: "success",
              });
            },
          ]);
          break;
      }
    },
  },
};
</script>
